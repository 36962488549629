
<template>
  <v-dialog
    v-model="display"
    fullscreen
    transition="dialog-bottom-transition"
    persistent
    scrollable
  >
    <v-card :elevation="0" flat color="secondary" height="100%">
      <ProfileHeader
        v-if="table && table.currentClient && table.currentClient !== 'ANONYMUS'"
        ref="profileHeader"
        :name="table && table.currentClient ? table.currentClient.name: ''"
        :email="table && table.currentClient ? table.currentClient.email : ''"
        :points="table && table.currentClient ? table.currentClient.points : 0"
        :image="table && table.currentClient ? table.currentClient.photoURL : ''"
        :id="table && table.currentClient ? table.currentClient.id: ''"
        :prevent-default-back="true"
        @onGoBack="$emit('close')"
      ></ProfileHeader>
      <v-container v-if="table" class="table-status-container">
        <div v-if="table.currentClient === 'ANONYMUS'" class="wrap-back-icon">
          <v-btn icon class="back-icon" @click="$emit('close')"></v-btn>
        </div>
        <p
          v-if="table.status === 'BUSY' && table.currentClient !== 'ANONYMUS'"
          class="table-name-container"
        >
          Facturación mesa:
          <span class="table-name">{{ table.name }}</span>
        </p>

        <p
          v-if="table.status === 'BUSY' && table.currentClient === 'ANONYMUS'"
          class="table-name-container table-name-bold"
        >Mesa: {{ table.name }}</p>
        <Invoice
          :dishes="table.currentOrder && table.currentOrder.dishes ? table.currentOrder.dishes : ({})"
          :table="table.id"
          :client="table.currentClient"
          :order="table.currentOrder && table.currentOrder.id"
          :iva="table.currentOrder && table.currentOrder.iva && table.currentOrder.iva.active ? table.currentOrder.iva.value : 0"
          :discount="table.currentOrder && table.currentOrder.discount ? table.currentOrder.discount : 0"
          :service="table.currentOrder && table.currentOrder.service ? table.currentOrder.service : 0"
          :headquaters="headquaters"
          :key="invoiceKey"
          @confirmed="orderConfirmed"
        ></Invoice>

        <v-snackbar
          v-model="sucess"
          :bottom="true"
          color="success"
          :left="true"
          :timeout="6000"
        >Operación completada con exito</v-snackbar>

        <v-snackbar
          v-model="error"
          :bottom="true"
          color="error"
          :left="true"
          :timeout="6000"
        >Ocurio un error, intenta mas tarde.</v-snackbar>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import ProfileHeader from "@components/Headers/ProfileHeader";
import Invoice from "@components/generals/Invoice";
import theme from "@/theme";
import uuid from "@utils/uuid";

export default {
  name: "TableState",
  components: { ProfileHeader, Invoice },
  props: {
    table: {
      type: Object,
      default: () => ({})
    },
    headquaters: {
      type: Array,
      default: () => []
    },
    noBack: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const { secondary } = theme;
    return {
      display: this.open,
      invoiceKey: uuid(),
      points: 0,
      error: false,
      secondary,
      loading: false,
      sucess: false
    };
  },
  watch: {
    open(val) {
      this.display = val;
    },
    table() {
      this.invoiceKey = uuid();
    }
  },
  updated() {
    if (this.open) {
      if (this.$refs.profileHeader) {
        this.$refs.profileHeader.onPWA = false;
      }
    }
  },
  methods: {
    orderConfirmed() {
      this.$emit("confirmed");
    }
  }
};
</script>

<style scoped lang="scss">
.wrap-back-icon {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
}
.back-icon {
  width: 30px;
  height: 30px;
  background-image: url("~@assets/icons/arrow-left.svg");
  background-size: contain;
  background-position: center;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  right: 10px;
}
.table-name-bold {
  font-family: Gibson-Bold;
  font-size: 30px !important;
}

.table-status-container-flex {
  margin: 0px auto;
}
.v-dialog__content--active {
  background: #16202f;
}
.table-name-container {
  font-size: 20px;
  color: white;
}
.table-name {
  color: #ed2236;
}
</style>
